import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AbstractRuntimeModelService,
  Attribute,
  ConditionValueType,
  CriteriaConditionJson,
  CriteriaConditionOperator,
  CriteriaConditionOperatorOnly,
  CriteriaOperator,
  CriteriaQuery,
  Field,
  KolibriEntity,
  PreDefinedCondition,
  Relation,
  Utility
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {MenuItem} from 'primeng/api';
import {EntityServiceFactory, ModelService, ModelTranslationService} from '../../../../../../api';

import {QueryOperator} from '../../../../entities/query-operator';
import {ToggleItem} from '../../../../entities/toggle-item';

import {QueryBuilderService} from '../../../../service/query-builder.service';
import {Converter} from '../../../converter/converter';

import {CustomWriter} from '../../custom-writer';

import {QueryBuilderGroupComponent} from '../query-builder-group/query-builder-group.component';
import {QueryBuilderDataService} from '../query-builder/query-builder-data.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ui-query-builder-rule]',
  templateUrl: './query-builder-rule.component.html',
  styleUrls: ['./query-builder-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderRuleComponent extends CustomWriter<CriteriaConditionJson> implements OnInit {
  public ConditionValueType: typeof ConditionValueType = ConditionValueType;
  public CriteriaConditionOperatorOnly: typeof CriteriaConditionOperatorOnly = CriteriaConditionOperatorOnly;
  @Input()
  public group: QueryBuilderGroupComponent;
  @Input()
  public breadcrumb: boolean;
  @Input()
  public first: boolean;
  @Input()
  public only: boolean;
  @Input()
  public deletable: boolean;
  @Input()
  public disable: boolean;
  @Input()
  public index: number;
  @Output()
  public onExecute: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onChanged: EventEmitter<void> = new EventEmitter<void>();
  // field ac
  public selectedField: Field;
  // operator ac
  public selectedOperator: QueryOperator;
  public operators: QueryOperator[] = [];
  public availableOperators: QueryOperator[] = [];
  public operatorsForField: QueryOperator[];
  public breadcrumbMenu: MenuItem[];
  public valueTypesMenu: (MenuItem & { value: ConditionValueType })[];
  public selectedValueType: MenuItem & { value: ConditionValueType };

  public constructor(private translateService: TranslateService, private queryBuilderService: QueryBuilderService,
                     private entityServiceFactory: EntityServiceFactory, public queryBuilderDataService: QueryBuilderDataService,
                     private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     cdr: ChangeDetectorRef) {
    super(cdr);
    this.filterOnTypeAndEntity = this.filterOnTypeAndEntity.bind(this);
  }

  public get relation(): Relation {
    return this.selectedField as Relation;
  }

  public get id(): string {
    return `${this.value.id.replace(/-/g, '_')}_rule`;
  }

  public get operatorConverter(): Converter<QueryOperator> {
    return {
      getAsString: (value: QueryOperator) => {
        if (value) {
          return value.getValue();
        }
        return null;
      },
      getAsObject: (s: string) => {
        const additionalOperator = _.find(this.queryBuilderDataService.additionalOperators as any[], {value: s}) as QueryOperator;
        if (additionalOperator) {
          return additionalOperator;
        }

        const value = CriteriaOperator[s];
        return this.getQueryOperator(value);
      }
    };
  }

  private pbreadcrumbString: Promise<string>;

  /**
   * Translates the rule build by the query builder to the selected language to be shown in the breadcrumb
   * @returns translated breadcrumb string
   */
  public get breadcrumbString(): Promise<string> {
    if (!this.pbreadcrumbString) {
      this.pbreadcrumbString = (async () => {
        const additionalField = _.find(this.queryBuilderDataService.additionalFields, {name: this.value.columnName});

        if (additionalField) {
          let queryOperator = _.find(this.queryBuilderDataService.additionalOperators, {operator: this.value.operator});
          if (!queryOperator) {
            queryOperator = this.getQueryOperator(this.value.operator);
          }
          let valueTranslation;
          const field = {...additionalField, ...(queryOperator.fieldOverride ?? {})};
          if ('targetId' in field) {
            // handle relation
            valueTranslation = await this.modelTranslationService.translateEntityValue(this.value.value, field);
          } else {
            valueTranslation = await this.modelTranslationService.translateValue(this.value.value, field, false, this.translateService.currentLang);
          }
          const showOperatorAndValue = (queryOperator.operator as CriteriaConditionOperator) !== CriteriaConditionOperatorOnly.SCRIPT
            && (queryOperator.operator as CriteriaConditionOperator) !== CriteriaConditionOperatorOnly.STATIC_SCRIPT;
          return `${additionalField.label} ${showOperatorAndValue ? this.getTranslatedOperator(
            queryOperator.operator) : ''} ${showOperatorAndValue ? valueTranslation : ''}`;
        }

        const columnNameField: Partial<Field> = this.modelService.getField(this.queryBuilderDataService.entityMeta.name, this.value.columnName);

        const meta = this.modelService.getEntity(columnNameField.entityId);
        let valueTranslation;
        if (this.value.scripted === ConditionValueType.SCRIPTED) {
          const condition = await this.entityServiceFactory.getService<PreDefinedCondition>('PreDefinedCondition').getEntityById(this.value.value);
          valueTranslation = condition.representativeString;
        } else if (this.value.scripted === ConditionValueType.SCRIPTED_VALUE) {
          // get translation of scripted value
          valueTranslation = this.translateService.instant('ConditionBuilder.ValueType.ScriptedValue.Value');
        } else if (Utility.isOperatorOnly(this.value.operator)) {
          valueTranslation = '';
          // date ranges come in as complex object and are already translated
        } else if (this.value.value.label) {
          valueTranslation = this.value.value.label;
        } else if (Array.isArray(this.value.value)) {
          const translatedValues = this.modelTranslationService.translateFieldValue(this.value.value, meta.name, columnNameField.name, false,
            this.translateService.currentLang);
          valueTranslation = (await Promise.all(translatedValues as any as Promise<string>[])).join(', ');
        } else {
          valueTranslation = await this.modelTranslationService.translateFieldValue(this.value.value, meta.name, columnNameField.name, false,
            this.translateService.currentLang);
        }

        return `${this.modelTranslationService.translateDotWalkField(this.queryBuilderDataService.entityMeta,
          this.value.columnName)} ${this.getTranslatedOperator(this.value.operator)} ${valueTranslation}`;
      })();
    }

    return this.pbreadcrumbString;
  }

  public get targetId(): string {
    return this.value.scripted === ConditionValueType.SCRIPTED ? 'PreDefinedCondition' :
      (this.selectedOperator.fieldOverride as Relation)?.targetId ?? this.relation.targetId;
  }

  public get columnName(): string {
    return this.value?.columnName ?? null;
  }

  public set columnName(s: string) {
    this.value.columnName = s;
  }

  private static getOperatorIcon(operator: CriteriaOperator): string {
    const base = 'fas fa-fw fa-';

    switch (operator) {
      case CriteriaOperator.EQUAL:
      case CriteriaOperator.IS:
        return base + 'equals';
      case CriteriaOperator.NOT_EQUAL:
      case CriteriaOperator.IS_NOT:
        return base + 'not-equal';
      case CriteriaOperator.LESS:
        return base + 'less-than';
      case CriteriaOperator.LESS_OR_EQUAL:
        return base + 'less-than-equal';
      case CriteriaOperator.GREATER:
        return base + 'greater-than';
      case CriteriaOperator.GREATER_OR_EQUAL:
        return base + 'greater-than-equal';
      case CriteriaOperator.IS_NULL:
      case CriteriaOperator.IS_EMPTY:
        return 'far fa-fw fa-circle';
      case CriteriaOperator.IS_NOT_EMPTY:
      case CriteriaOperator.IS_NOT_NULL:
        return base + 'circle';
      case CriteriaOperator.IN:
      case CriteriaOperator.MEMBER_OF:
      case CriteriaOperator.ALL_IN:
        return base + 'sign-in-alt';
      case CriteriaOperator.NOT_IN:
      case CriteriaOperator.NOT_MEMBER_OF:
        return base + 'sign-out-alt';
      case CriteriaOperator.BETWEEN:
      case CriteriaOperator.NOT_BETWEEN:
      case CriteriaOperator.DATE_RANGE:
        return base + 'arrows-alt-h';
      case CriteriaOperator.CONTAINS:
      case CriteriaOperator.NOT_CONTAINS:
      case CriteriaOperator.BEGINS_WITH:
      case CriteriaOperator.NOT_BEGINS_WITH:
      case CriteriaOperator.ENDS_WITH:
      case CriteriaOperator.NOT_ENDS_WITH:
        return base + 'search';
    }
  }

  private static isIconOnlyOperator(operator: CriteriaOperator): boolean {
    switch (operator) {
      case CriteriaOperator.EQUAL:
      case CriteriaOperator.NOT_EQUAL:
      case CriteriaOperator.LESS:
      case CriteriaOperator.LESS_OR_EQUAL:
      case CriteriaOperator.GREATER:
      case CriteriaOperator.GREATER_OR_EQUAL:
      case CriteriaOperator.IS:
      case CriteriaOperator.IS_NOT:
        return true;
      case CriteriaOperator.BETWEEN:
      case CriteriaOperator.IN:
      case CriteriaOperator.NOT_IN:
      case CriteriaOperator.BEGINS_WITH:
      case CriteriaOperator.NOT_BEGINS_WITH:
      case CriteriaOperator.CONTAINS:
      case CriteriaOperator.NOT_CONTAINS:
      case CriteriaOperator.ENDS_WITH:
      case CriteriaOperator.NOT_ENDS_WITH:
      case CriteriaOperator.IS_EMPTY:
      case CriteriaOperator.IS_NOT_EMPTY:
      case CriteriaOperator.NOT_BETWEEN:
      case CriteriaOperator.DATE_RANGE:
      case CriteriaOperator.MEMBER_OF:
      case CriteriaOperator.NOT_MEMBER_OF:
      case CriteriaOperator.ALL_IN:
      case CriteriaOperator.IS_NULL:
      case CriteriaOperator.IS_NOT_NULL:
        return false;
    }
  }

  /**
   * Adds the correct type or entity to the query for the predefined conditions
   */
  public filterOnTypeAndEntity(query: CriteriaQuery<KolibriEntity>): void {
    if (this.value.scripted === ConditionValueType.SCRIPTED) {
      const typeId = (this.selectedField as Attribute).typeId;
      if (typeId) {
        query.addCondition('typeId', CriteriaOperator.IS, typeId);
      } else {
        query.addCondition('typeId', CriteriaOperator.IS_NULL);
      }
      if (this.targetId && this.targetId !== 'PreDefinedCondition') {
        // Switch the AC Value depending on Operator on User
        query.addCondition('entityId', CriteriaOperator.IS, this.targetId);
      }
    }
  }

  public ngOnInit(): void {
    this.getAllTranslatedOperators();

    this.breadcrumbMenu = [
      new ToggleItem(this.value, this.translateService, () => {
        this.value.active = !this.value.active;
        this.forceUpdate();
        this.onExecute.emit();
      }),
      {
        label: this.translateService.instant('QueryBuilder.Rule.Remove'),
        icon: 'fas fa-fw fa-trash-alt',
        styleClass: 'p-menuitem--negative',
        command: () => {
          this.removeRule();
          this.onExecute.emit();
        }
      }];

    const self = this;
    const command = function (this: MenuItem & { value: ConditionValueType }): void {
      self.value.scripted = this.value;
      self.value.value = undefined;
      self.selectedValueType = this;
      self.forceUpdate();
    };
    this.valueTypesMenu = [
      {
        icon: 'fas fa-fw fa-i-cursor',
        value: ConditionValueType.SCRIPTED,
        label: this.translateService.instant('ConditionBuilder.ValueType.Scripted.Value'),
        command,
        visible: this.queryBuilderDataService.allowScriptedRule === true
      },
      {
        icon: 'fas fa-fw fa-subscript',
        value: ConditionValueType.MANUAL,
        label: this.translateService.instant('ConditionBuilder.ValueType.Manual.Value'),
        command
      },
      {
        icon: 'fas fa-fw fa-scroll',
        value: ConditionValueType.SCRIPTED_VALUE,
        label: this.translateService.instant('ConditionBuilder.ValueType.ScriptedValue.Value'),
        command,
        visible: this.queryBuilderDataService.allowScriptedValue === true
      }];
    this.selectedValueType = _.find(this.valueTypesMenu, {value: this.value?.scripted ?? ConditionValueType.MANUAL});
  }

  public removeRule(): void {
    _.remove(this.group.value.whereCondition, rule1 => rule1.id === this.value.id);
    this.forceUpdate();
  }

  public moveRule(value?: CriteriaConditionJson): void {
    this.queryBuilderService.draggedQueryElement = value;
    this.queryBuilderService.onDrop = () => {
      this.removeRule();
    };
  }

  public searchOperator($event: any): void {
    this.operators = _.filter(this.operatorsForField, operator => Utility.matches(operator.label, $event.query));

    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
    this.cdr.detectChanges();
  }

  public selectField($event: Field, clear: boolean = true): void {
    this.selectedField = $event;
    if (this.selectedField) {
      this.operatorsForField = _.filter(this.availableOperators, operator => {
        const type = this.modelService.getFieldType(this.selectedField) || {};
        const isToMany = Utility.isToManyField(this.selectedField);
        const isEntityTarget = this.modelService.getTypeName(this.selectedField) === AbstractRuntimeModelService.KOLIBRI_ENTITY || isToMany;
        const additionalField = _.find(this.queryBuilderDataService.additionalFields, {name: this.selectedField.name});
        const isMultipleAttribute = (this.selectedField as Attribute).multiple;

        if (additionalField?.validOperators && !isMultipleAttribute) {
          return additionalField.validOperators.includes(operator.operator);
        }

        if (!operator.isAllowed(this.selectedField, $event.path ?? $event.name)) {
          return false;
        }

        // no type === KolibriEntity, skip equality checks for them: id compare is required
        if (operator.isEqualityOperator() && isEntityTarget && !isMultipleAttribute) {
          return false;
        }

        // only is empty for lists otherwise pointless
        if (operator.isListOperator() && !isToMany && !isMultipleAttribute) {
          return false;
        }

        // no null checks for list relations, does not work
        if (operator.isNullOperator() && (isToMany || isMultipleAttribute)) {
          return false;
        }

        // comparison only for valid types
        if (operator.isCompareOperator() && !operator.comparableTypes().includes(type.name)) {
          return false;
        }

        if (operator.isInOperator() && (type.name === 'Boolean' || type.name === 'LargeText' || type.name === 'Code')) {
          return false;
        }

        if (operator.isDateOperator() && type.name !== 'Date') {
          return false;
        }

        if (operator.isSubstringOperator() && (isEntityTarget || isMultipleAttribute || type.name === 'Boolean' || type.name === 'Date')) {
          return false;
        }

        // operator begins with or ends with for choices makes no sense
        if ((operator.operator === CriteriaOperator.ENDS_WITH
            || operator.operator === CriteriaOperator.BEGINS_WITH
            || operator.operator === CriteriaOperator.NOT_ENDS_WITH
            || operator.operator === CriteriaOperator.NOT_BEGINS_WITH)
          && type.entityClass === 'Choice') {
          return false;
        }

        // skip is and is not for simply types, choices and list relations
        // noinspection RedundantIfStatementJS, keeping it for not breaking my mind
        if (operator.isEntityIdentityOperator() && (!isEntityTarget || isToMany)) {
          return false;
        }
        return true;
      });

      if (this.operatorsForField.length === 1) {
        this.selectOperator(this.operatorsForField[0], clear);
      }

      if (clear) {
        this.selectOperator(_.find(this.availableOperators, o => o.isDefaultValue(this.selectedField)), true);
        this.value.operator = this.selectedOperator.operator;
        // like null on hide for value.scripted and p-menu (value.value will be set in selectOperator)
        if (!this.showValueContainer() || !this.showValueTypeToggle()) {
          this.value.scripted = ConditionValueType.MANUAL;
          this.selectedValueType = _.find(this.valueTypesMenu, item => item.value === ConditionValueType.MANUAL);
        }
      }
    } else {
      this.value.operator = undefined;
      this.value.value = undefined;
    }
    this.forceUpdate();
  }

  public showValueTypeToggle(): boolean {
    return !this.disable && this.columnName !== '$Script';
  }

  public showValueContainer(): boolean {
    return this.selectedField && this.selectedOperator?.isValueRequired();
  }

  public selectOperator($event: QueryOperator, clearValue: boolean = true): void {
    this.selectedOperator = $event;
    if (clearValue) {
      // only apply display transformations for additional fields
      if (_.find(this.queryBuilderDataService.additionalFields, {name: this.selectedField.name})) {
        const defaultValue = this.modelService.getDisplayTransformation((this.selectedField as Attribute).displayTransformId)?.defaultValue;
        this.value.value = defaultValue ?? undefined;
      } else {
        this.value.value = undefined;
      }
    }
    this.forceUpdate();
  }

  /**
   * returns the translated operator or an icon if possible (as html)
   * @param operator the operator to translate
   * @private
   */
  private getTranslatedOperator(operator: CriteriaOperator): string {
    const additionalOperator = _.find(this.queryBuilderDataService.additionalOperators, {operator});
    if (additionalOperator) {
      return `<span class="one-filter-nav__item-additionalOperator">${additionalOperator.label}</span>`;
    }
    const operatorTranslation = `<span class="one-filter-nav__item-operator">${this.modelTranslationService.translateOperator(operator)}</span>`;
    return QueryBuilderRuleComponent.isIconOnlyOperator(
      operator) ? `<i class="one-filter-nav__item-icon ${QueryBuilderRuleComponent.getOperatorIcon(
      operator)}"></i>` : operatorTranslation;
  }

  private getAllTranslatedOperators(): void {
    for (const operator in CriteriaOperator) {
      if ((operator in CriteriaOperator)) {
        const name = CriteriaOperator[operator];

        // this is not supported in jsf either
        if (name === 'BETWEEN' || name === 'NOT_BETWEEN') {
          continue;
        }
        const queryOperator = this.getQueryOperator(name);
        this.availableOperators.push(queryOperator);
      }
    }
    this.availableOperators.push(...this.queryBuilderDataService.additionalOperators);
    this.availableOperators = _.sortBy(this.availableOperators, 'label');
  }

  private getQueryOperator(operator: CriteriaOperator): QueryOperator {
    const translation = this.modelTranslationService.translateOperator(operator);
    return new QueryOperator(operator, translation, QueryBuilderRuleComponent.getOperatorIcon(operator));
  }
}
